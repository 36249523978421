<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      sortable
      :headers="headers"
      :show-operations="false"
      :resource-query="resourceQuery"
      no-meta-check
      :buttons="computedButtons"
      :custom-resource="customResource"
      resource="analytics"
      route-base="/reports/financial_accounting/payment_options"
      :transform-fetched-data="transformFetchedData"
      show-filter
      :search-filters="filtersList"
      do-route-filters
      prune-search-filters
      :locale="locale"
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import qs from 'qs'
import pick from 'just-pick'
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import { applyFiltersBeforeRouteEnter, getRangeFor } from '@/utils/date'
import { useExportsStore } from '@/store/exports'

const IS_LEGACY = true

export default {
  metaInfo() {
    return {
      title: this.$t('pages.payment_options.title')
    }
  },
  beforeRouteEnter: (to, _, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, from, next) {
    // as UX enhancement we are going to persist some of the queries
    const elegibleObj = pick(safeGet(qs.parse(to.query), 'filter') || {}, [
      'date',
      'branch_group'
    ])
    this.$emit('route-filter', elegibleObj)

    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLegacy: true,
      loadingExport: false,
      tableData: [],
      query: {
        legacy: IS_LEGACY
      },
      sort: { prop: null, order: null },
      headers: [
        {
          label: this.$t('pages.reports.payment_options.payment_method'),
          field: 'name',
          fallback: '-',
          truncate: true
        },
        {
          label: this.$t('pages.reports.payment_options.qty'),
          field: 'qty',
          fallback: '-',
          align: 'right',
          truncate: true,
          sortType: 'number'
        },
        {
          label: this.$t('pages.reports.payment_options.total_amount'),
          field: 'total_amount',
          fallback: '-',
          truncate: true,
          type: 'currency',
          align: 'right',
          formatter: (row) => {
            const amount = safeGet(row, 'total_amount')
            const currency = safeGet(row, 'currency')
            return this.$formatCurrency(amount, currency)
          },
          sortType: 'currency'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      branchNumber: 'Config/getCurrentBranchNumber',
      locale: 'Config/getLocale',
      defaultDateSelected: 'Config/getDefaultDateSelected'
    }),
    filtersList() {
      return [
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'date',
          type: 'daterange',
          prop: ['start', 'end'],
          label: this.$t('pages.payment_options.all.filters.date.label'),
          formatValue: (value) => this.$date.formatDateRange(value),
          closable: false,
          noFutureDates: true,
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          })
        }
      ]
    },
    resourceQuery() {
      return {
        legacy: this.isLegacy,
        query: {
          branch_number: this.branchNumber || undefined
        }
      }
    },
    defaultSort() {
      return this.sort
    },
    customResource() {
      return th.analytics().paymentOptions()
    },
    buttons() {
      return [
        {
          type: 'custom_export',
          scopes: ['reports_financial_accounting:payment_options:export'],
          clickHandler: ({ handleDownload, resourceOptions }) => {
            this.handleExport({ handleDownload, resourceOptions })
          }
        }
      ]
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  mounted() {
    this.getTable()

    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.payment_options.title')
        })
      })
    },
    async getTable() {
      try {
        const { data } = await th
          .analytics()
          .paymentOptions()
          .getAll(this.query)
        this.tableData = data[0].values
      } catch {
        this.tableData = []
      }
    },
    async sortChange(info) {
      if (!info.order) {
        this.query.cursor_field = null
      } else if (info.order === 'ascending') {
        this.query.cursor_field = `+${info.prop}`
      } else if (info.order === 'descending') {
        this.query.cursor_field = `-${info.prop}`
      }
      await this.getTable()
    },
    async handleExport({ resourceOptions }) {
      const query = {
        ...this.query,
        ...resourceOptions.query,
        format: 'csv',
        filename_prefix: this.$t('pages.payment_options.title'),
        timezone: this.timeZone || 'Europe/Berlin'
      }

      try {
        const {
          data
        } = await th
          .analyticsHandlersV1()
          .analytics.reports.AnalyticsReportsPaymentOptions.getAll(query)

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.payment_options.title',
            date: new Date(),
            action: {
              entity: 'analyticsHandlersV1',
              path: 'analytics.reports.AnalyticsReportsPaymentOptions',
              handler: 'getAll',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.payment_options.title')
          })
        })
      }
    },
    transformFetchedData(data) {
      return safeGet(data[0], 'values')
    },
    refresh() {
      this.$nextTick(() => {
        this.$refs.table.refresh()
      })
    }
  }
}
</script>
